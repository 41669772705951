import React, { useState, useEffect } from "react";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { styled } from "@mui/material/styles";

import Layout from "../../layouts/default";
import Logo from "../../components/logo";

import api from "../../services/api";

import { useAuth } from "../../context/AuthContext";

import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Main() {
  const [contatoOpen, setContatoOpen] = useState(false);
  const [precoOuro, setPrecoOuro] = useState(0);
  const [precoPrata, setPrecoPrata] = useState(0);
  const [precoData, setPrecoData] = useState("0");
  const { signOut } = useAuth();

  const handleClose = () => {
    setContatoOpen(false);
  };

  const apiPrecos = async () => {
    await api
      .get("/api/metal-precos/ultimo")
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.ouro) setPrecoOuro(resp.data.ouro);
        if (resp.data.prata) setPrecoPrata(resp.data.prata);
        if (resp.data.data) setPrecoData(resp.data.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        // dsds
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    const pegarPrecos = async () => {
      await apiPrecos();
    };

    pegarPrecos();
  }, []);

  return (
    <Layout>
      <Dialog
        open={contatoOpen}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Atendimento</DialogTitle>
        <DialogContent sx={{ minWidth: 300 }}>
          {/* <DialogContentText id="scroll-dialog-description"> */}
          <DialogContentText>
            <strong>Horário de Atendimento</strong>
            <br />
            Seg. à Sex: 9:00 às 17:00
            <br />
            Sáb.: 9:00 às 12:00
          </DialogContentText>
          <DialogContentText sx={{ mt: 1 }}>
            <strong>Alterações e Cancelamentos de pedido</strong>
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/5562982304876"
            >
              (62) 98230-4876
            </a>
          </DialogContentText>
          <DialogContentText sx={{ mt: 1 }}>
            <strong>Fechamento de Serviços</strong>
            <br />
            Fazer o pagamento conforme o valor do dia.
          </DialogContentText>
          <DialogContentText sx={{ mt: 1 }}>
            <strong>Orçamentos</strong>
            <br /> Responderemos por ordem de chegada, sem previsão de tempo de
            resposta
          </DialogContentText>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <Grid sx={{ pt: 8, pb: 8 }}>
        <Logo />
      </Grid>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Item sx={{ width: "33%", backgroundColor: "#d4af37" }}>
          <strong>AU 18k</strong>
          <br />{" "}
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(precoOuro * 0.75)}
          /g
        </Item>
        <Item
          sx={{
            width: "33%",
            background: "#e3e3de",
          }}
        >
          <strong>BR/RS 18K</strong>
          <br />
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(precoOuro * 0.88)}
          /g
        </Item>
        <Item sx={{ width: "33%" }}>
          <strong>AG 950</strong>
          <br />{" "}
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(precoPrata * 3)}
          /g
        </Item>
      </Stack>
      <Stack sx={{ pt: 2 }}>
        <Typography fontSize={10}>
          <strong>Ultima Consulta: </strong>
          {new Date(precoData).toLocaleDateString("pt-BR")}
        </Typography>
      </Stack>
      <Box noValidate sx={{ pl: 2, pr: 2 }}>
        <Button
          component={Link}
          to="/pedido/novo"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Fazer Novo Pedido
        </Button>
        <Button
          component={Link}
          to="/pedido/todos"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Acompanhar Pedidos
        </Button>
        <Button
          fullWidth
          disabled
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mb: 2 }}
        >
          Catálogo (em breve)
        </Button>
        <Button
          href="https://criador.isadorajoias.com.br"
          fullWidth
          // disabled
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mb: 2 }}
        >
          Criador 3D (em teste)
        </Button>
        <Button
          onClick={() => setContatoOpen(true)}
          fullWidth
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
          size="small"
        >
          Atendimento
        </Button>
        <Button onClick={() => signOut()} fullWidth sx={{ mt: 2, mb: 2 }}>
          Sair
        </Button>
      </Box>
    </Layout>
  );
}
